export default {
    data() {
        return {
            itemsGroup: {
                column: []
            },
            listDeploy: {
                row: [
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "menuid",
                        connector: {
                            props: {
                                required: true,
                                label: "三级栏目ID",
                                disabled: false,
                                rules: [(v)=> {
                                    let reg = /^[0-9a-zA-Z]+$/;
                                    if(reg.test(v)) {
                                        return true;
                                    }else if(v == ""){
                                        return '三级栏目ID不能为空'
                                    }
                                    return "不能输入英文和数字以外";
                                }]
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "mname",
                        connector: {
                            props: {
                                required: true,
                                label: "三级栏目名称",
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "orgmenuid",
                        connector: {
                            props: {
                                required: true,
                                label: "二级栏目",
                                items: []
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "comptype",
                        connector: {
                            props: {
                                // required: true,
                                label: "栏目模版",
                                items: []
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WAutocomplete",
                        property: "ordertype",
                        connector: {
                            props: {
                                // required: true,
                                label: "新增内容排序",
                                items: []
                            },
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "WSwitches",
                        property: 'show',
                        disabledDeleteDefault: true,
                        connector: {
                            props: {
                                // extension: {
                                //     disabled: true,
                                // },
                                label: '显示状态',
                                inset: true,
                                dense: true
                            }
                        },
                    },
                    {
                        cols: 6,
                        subassembly: "VTextField",
                        property: "seq",
                        connector: {
                            props: {
                                label: "排序号",
                            },
                        },
                    },
                ]
            }
        }
    }
}